<template>
  <section>
    <div class="richtext">
      <h2><strong>How do Chemical Dosing Pumps Work?&nbsp;</strong><br /></h2>
      <p>
        <strong><br /></strong>
      </p>
      <p>
        A reciprocating membrane cap/plunger pump is used as the metering pump
        in most cases. A carefully determined volume of liquid is sucked in
        during the reciprocating stroke. This portion of the liquid will be
        pressed into the metering pipe during the pressure stroke. The pump
        settings can be modified to maintain consistent metering accuracy.
      </p>
      <p><br /></p>
      <h2><strong>Application of Micro Chemical Dosing Pump</strong></h2>
      <p><br /></p>
      <p>
        The dosing pumps/micro dosing pumps with small capacity or flow rate,
        that can achieve the precise value of the flow controlling within the
        volume range of milliliter flow, is an important part of chemical
        medicine, biotechnology, and mechanical engineering. Because of the
        special requirements of those fields, the stability of the dosing
        pumps/micro pumps is highly required. With the exquisite craft, micro
        chemical dosing pumps make their micro gear pumps used broadly in those
        fields.
      </p>
      <p><br /></p>
      <p>
        Micro chemical dosing pump has advantages of wide flow range, wide
        viscosity range,&nbsp; small size, direct motor drive, few pulse, high
        precision, easy to adjust, corrosion resistance, and no leakage, so it's
        a good choice for chemical measurement.
      </p>
      <p><br /></p>
      <p>
        The largest advantage of micro dosing pumps is that it has no flow pulse
        and pressure pulse. Gear dosing pumps adopt direct motor connection
        without the reciprocating motion mechanism of diaphragm pumps.
        Consequently, compared with diaphragm pumps, micro dosing pumps have a
        smaller size and higher cost-efficiency. Their flows are easy to adjust,
        and you just need to adjust the rotational speed of the motor to satisfy
        your needs.
      </p>
      <p><br /></p>
      <h2>
        <strong
          >The Difference between Micro&nbsp;Chemical Dosing&nbsp;Pump and
          Metering Pump</strong
        >
      </h2>
      <p>
        <strong><br /></strong>
      </p>
      <p><strong>1. Different application areas</strong></p>
      <p>
        Micro chemical dosing&nbsp;pumps are often used in the pharmaceutical
        industry, drug delivery, automotive spot coating, line coating, sealing,
        packaging precision equipment, etc., while metering pumps are mainly
        used for corrosive liquid transportation, such as petrochemical,
        pharmaceutical, food, etc.
      </p>
      <p>
        <strong><br /></strong>
      </p>
      <p><strong>2. Different functions</strong></p>
      <p>
        In addition to the function of transporting trace elements, the
        <a href="">metering pump</a> 
        also has the characteristics of accurate measurement.
      </p>
      <p>
        <strong><br /></strong>
      </p>
      <p><strong>3. The structure is different</strong></p>
      <p>
        The micro dosing pump is composed of a controller, an actuator and a
        syringe; while the metering pump is composed of three parts: a motor, a
        transmission box, and a cylinder.
      </p>
      <p>
        <strong><br /></strong>
      </p>
      <p><strong>4. Different advantages</strong></p>
      <p>
        The epoxy metering pump has the advantages of stable operation, no
        noise, small size, light weight, and convenient maintenance; the micro
        chemical pump has the advantages of small size and light weight,
        accurate injection of drugs, small amounts, and suitable for long-term
        micro-administration.
      </p>
      <p><br /></p>
      <h2>
        <strong>Which Pumps are Commonly Used for Chemical Dosing?</strong>
      </h2>
      <p>
        <br />In order to effectively transport chemicals, high pressure must be
        used to overcome friction loss, but the toxic nature of chemicals also
        requires that leakage be minimized during efficient transmission.
        NEWDOSE metering pump and chemical dosing system are reliable choices
        for high-pressure chemical filling. The diaphragm fluid end provides
        safe and effective chemical delivery with only minor or no leakage. In
        addition, the modular design enables multiple pump heads and customized
        configurations to ensure that the performance is maximized in the case
        of small size.
      </p>
      <p><br/></p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Micro Chemical Dosing Pump",
}
</script>